module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"https://admin.anaemilia.pt/adm","url":"https://admin.anaemilia.pt/adm/gq","protocol":"https","hostingWPCOM":false,"production":{"allow404Images":true},"useACF":true,"withWebp":true,"acfOptionPageIds":[],"backgroundColor":"transparent","verboseOutput":true,"perPage":100,"type":{"__all":{"limit":50}},"html":{"useGatsbyImage":true,"createStaticFiles":true},"schema":{"requestConcurrency":50},"includedRoutes":["**/categories","**/posts","**/pages","**/media","**/tags","**/taxonomies","**/users","**/*/*/menu","**/*/*/menu-locations"],"excludedRoutes":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"https://www.anaemilia.pt/","protocol":"https","backgroundColor":"transparent","useACF":true,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","withWebp":true,"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"manifestName","short_name":"shortName","start_url":"/","background_color":"#afc4c0","theme_color":"#afc4c0","display":"standalone","icon":"src/assets/img/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"378f4edf066bd9b811223e9539f1c979"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
