// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-blog-js": () => import("./../../../src/pagesInternal/blog.js" /* webpackChunkName: "component---src-pages-internal-blog-js" */),
  "component---src-pages-internal-cookies-js": () => import("./../../../src/pagesInternal/cookies.js" /* webpackChunkName: "component---src-pages-internal-cookies-js" */),
  "component---src-pages-internal-privacy-js": () => import("./../../../src/pagesInternal/privacy.js" /* webpackChunkName: "component---src-pages-internal-privacy-js" */),
  "component---src-pages-internal-terms-js": () => import("./../../../src/pagesInternal/terms.js" /* webpackChunkName: "component---src-pages-internal-terms-js" */),
  "component---src-pages-para-ti-js": () => import("./../../../src/pages/paraTi.js" /* webpackChunkName: "component---src-pages-para-ti-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

